.bgImg{
    width: 100%;
    height: 100vh;
    background: url("https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/heifeng2.jpg");
    background-size: 100% 100%;
    position: relative;
    font-family: STKaiti, Kaiti SC;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
}

@keyframes fadeInLeft {
    from {
      opacity: 0;
    //   transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 0.85;
    //   transform: none;
    }
  }
  
.pInfo{
    margin-left: 6.125rem;
    width: 53.125rem;
    color: #dfdad3;
    background-color: rgba(0,0,0,0.85);
    // background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/praise_top_bg.png');
    // background-size: 100% 100%;
    opacity: 0.85;
    padding: 1.875rem;
    border-radius: 1.25rem;

    // animation-duration: 2.5s;
    // animation-fill-mode: both;
    // animation-name: fadeInLeft;
}

.tagContainer{
    position: absolute;
    right: 4.375rem;
    top:30vh;
    overflow: hidden;
    height: 0rem;
    opacity: 0;
    transform: translateY(-20vh);
    // animation-duration: 3s;
    // animation-fill-mode: both;
    // animation-name: tagAnim;
}

.tagContainerHide{
    position: absolute;
    right: 9.375rem;
    top: 9.375rem;
    overflow: hidden;
    height: 0rem;
    opacity: 0;
}

.showTag{
    // position: absolute;
    // width: 3.125rem;
    // height: 12.5rem;
    padding: 1.875rem .75rem;
    // background-color: #000;
    background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/bosstag.png');
    background-size: 100% 100%;
    .tagText{
        writing-mode: vertical-rl; /* 文字竖向排列 */
        text-orientation: upright; /* 使每个字保持直立 */
        text-align: center;
        color: white;
        margin: auto;
        font-size: 1.4375rem;
    }


    // mask-image: linear-gradient(to bottom, black 0%, black 100%); /* 设置遮罩 */
    // mask-size: 100% 0; /* 初始遮罩高度为0 */
}

[data-aos="tag1"] {
    height: 0;
    opacity: 0;
}

:global([data-aos="tag1"].aos-animate) {
    height: 9.5rem ;
    opacity: 1;
    // transform: none;
}

@keyframes tagAnim {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: 9.5rem ;
        opacity: 1;
    }
  }

.avatar{
    width: 8.125rem;
    height: 8.125rem;
    // background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/cover.jpg');
    // background-size: 100% 100%;
    // border-radius: 50%;
    margin-top: 4.875rem;
    margin-bottom: 1.875rem;
    position: relative;
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    .avatarImg{
        width: 8.125rem;
        height: 8.125rem;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        z-index: 101;
    }
}

@keyframes pulsate {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0.0;
    }
}

.avatar:before {
    content: "";
    border: .625rem solid rgb(201, 210, 164);
    border-radius: 50%;
    height: 8.125rem;
    width: 8.125rem;
    position: absolute;
    left: -0.625rem;
    top: -0.625rem;
    -webkit-animation: pulsate 1.6s ease-out;
    animation: pulsate 1.6s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0.0;
    z-index: 99;
}

.name{
    font-size: 4.25rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.personInfoFlex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.personInfo{
    font-size: 2.625rem;
    flex: 1 1 50%;
    margin-bottom: 1.25rem;
}

.summary{
    font-size: 1.6875rem;
    width: 100%;
    margin-top: 1.5625rem;
    line-height: 1.15;
}

.motto{
    font-size: 2.1875rem;
    width: 100%;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@-webkit-keyframes blink {
    50% {
        opacity: 0;
    }
}

.motto::after{
    position: relative;
    top: .3125rem;
    display: inline-block;
    height: 1.75rem;
    margin-left: .3125rem;
    content: " ";
    -webkit-animation: blink .5s step-end infinite alternate;
    animation: blink .5s step-end infinite alternate;
    border-right: .125rem solid;
}