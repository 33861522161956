.App {
  text-align: center;
  position: relative;
  font-family: STKaiti, Kaiti SC;
  background-color: #282c34;
}

.notsupport{
  height: 100vh;
  background: url("https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/mobile.jpg");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .text{
    margin-bottom: 10vh;
    font-family: STKaiti, Kaiti SC;
    font-size: 2.5rem;
    color: white;
  }
}

.tabBar{
    position: fixed;
    top: 0;
    left: 3.125rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items:flex-start;
    justify-content: center;
}

.tabItem{
    // display: flex;
    // flex-direction: row;
    // align-items: center;
}

.iconContainer{
  // width: 2.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  position: relative;
  &:hover{
    .iconHover{
      display: block;
    }
    .icon{
      opacity: 1;
    }
    .infoText{
      opacity: 1;
    }
  }
}

.active{
  .icon{
    background-image: url("./assets/active.png");
    opacity: 1;
  }
  .infoText{
    opacity: 1;
    font-size: 1.5625rem;
  }
  &:hover{
    .iconHover{
      display: none;
    }
  }
}

.icon{
  width: 2.125rem;
  height: 4rem;
  background-image: url("./assets/nav_df.png");
  background-size: 100% 100%;
  opacity: 0.4;
}

.iconHover{
  position: absolute;
  top: 0;
  width: 2.125rem;
  height: 4rem;
  background-image: url("./assets/nav_hover.png");
  background-size: 100% 100%;
  display: none;

}

.infoText{
  margin-left: .625rem;
  font-size: 1.25rem;
  color: white;
  cursor:default;
  opacity: 0.6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(.625rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
