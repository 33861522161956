.bgImg{
    width: 100%;
    height: 100vh;
    background: url("https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/huanghua.jpg");
    background-size: 100% 100%;
    position: relative;
    font-family: STKaiti, Kaiti SC;
    display: flex;
    justify-content: center;
    align-items: center;
}

.workContainer{
    margin-left: 6.125rem;
    width: 53.125rem;
    color: #dfdad3;
    background-color: rgba(0,0,0,0.75);
    // background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/praise_top_bg.png');
    // background-size: 100% 100%;
    opacity: 0.85;
    padding: 1.875rem;
    border-radius: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .625rem .625rem;
    .header{
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 1.25rem;
        align-items: center;
        justify-content: space-between;
        .department{
            margin-left: auto;
            margin-right: 2.1875rem;
            font-size: 1.5rem;
        }
        .company{
            font-size: 2.625rem;
        }
        .position{
            // margin-left: auto;
            margin-right: 2.1875rem;
        }
    }
    .projects{
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 1.25rem 0;
        .ind{
            font-size: 1.5rem;
            display: flex;
        }
        .stack{
            margin-top: .375rem;
            font-size: 1.25rem;
            font-style: italic;
            font-weight: bold;
        }
        .content{
            margin-top: .25rem;
            font-size: 1.125rem;
            white-space: pre-line;
            line-height: 1.3;
        }
    }
}

.tagContainer{
    position: absolute;
    right: 4.375rem;
    top:30vh;
    transform: translateY(-20vh);
    overflow: hidden;
    height: 0rem;
    opacity: 0;
    // animation-duration: 3s;
    // animation-fill-mode: both;
    // animation-name: tagAnim;
    .showTag{
        // position: absolute;
        // width: 3.125rem;
        // height: 12.5rem;
        padding: 1.25rem .625rem;
        background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/bosstag.png');
        background-size: 100% 100%;
        .tagText{
            writing-mode: vertical-rl; /* 文字竖向排列 */
            text-orientation: upright; /* 使每个字保持直立 */
            text-align: center;
            color: white;
            margin: auto;
            font-size: 1.4375rem;
        }
    
    
        // mask-image: linear-gradient(to bottom, black 0%, black 100%); /* 设置遮罩 */
        // mask-size: 100% 0; /* 初始遮罩高度为0 */
    }
}

[data-aos="tag4"] {
    height: 0;
    opacity: 0;
}

:global([data-aos="tag4"].aos-animate) {
    height: 9.5rem ;
    opacity: 1;
    // transform: none;
}