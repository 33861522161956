.bgImg{
    width: 100%;
    height: 100vh;
    background: url("https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/huangfeng.jpg");
    background-size: 100% 100%;
    position: relative;
    font-family: STKaiti, Kaiti SC;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tagContainer{
    position: absolute;
    right: 4.375rem;
    top:30vh;
    transform: translateY(-20vh);
    overflow: hidden;
    height: 0rem;
    opacity: 0;
    // animation-duration: 3s;
    // animation-fill-mode: both;
    // animation-name: tagAnim;
}

.tagContainerHide{
    position: absolute;
    right: 9.375rem;
    top: 9.375rem;
    overflow: hidden;
    height: 0rem;
    opacity: 0;
}

.showTag{
    // position: absolute;
    // width: 3.125rem;
    // height: 12.5rem;
    padding: 1.25rem .625rem;
    background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/bosstag.png');
    background-size: 100% 100%;
    .tagText{
        writing-mode: vertical-rl; /* 文字竖向排列 */
        text-orientation: upright; /* 使每个字保持直立 */
        text-align: center;
        color: white;
        margin: auto;
        font-size: 1.4375rem;
    }


    // mask-image: linear-gradient(to bottom, black 0%, black 100%); /* 设置遮罩 */
    // mask-size: 100% 0; /* 初始遮罩高度为0 */
}

@keyframes tagAnim {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: 8.25rem ;
        opacity: 1;
    }
  }


.skillsContainer{
    width: 53.125rem;
    margin-left: 6.125rem;
    color: #dfdad3;
    background-color: #000000;
    // background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/praise_top_bg.png');
    // background-size: 100% 100%;
    // opacity: 0.85;
    padding: 3.75rem 1.875rem;
    border-radius: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1.875rem .625rem;
}

.skillBox{
    width: 7.375rem;
    height: 6.25rem;
    position: relative;
    // background-color: gray;
    background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/score.jpg');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .score{
        margin-top: .625rem;
        color: rgb(192, 174, 127);
        font-size: 2.625rem;
    }
    .totalScore{
        margin-top: -0.3125rem;
        font-size: 1.375rem;
    }
    .subject{
        color: white;
    }
}

[data-aos="my-diy"] {
    opacity: 0;
    transition-property: opacity, transform;
}

:global([data-aos="my-diy"].aos-animate) {
    opacity: 0.9;
    // transform: none;
}

[data-aos="tag2"] {
    height: 0;
    opacity: 0;
}

:global([data-aos="tag2"].aos-animate) {
    height: 8.25rem ;
    opacity: 1;
    // transform: none;
}