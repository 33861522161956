.bgImg{
    width: 100%;
    height: 100vh;
    background: url("https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/xitian.jpg");
    background-size: 100% 100%;
    position: relative;
    font-family: STKaiti, Kaiti SC;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eduContainer{
    margin-left: 6.125rem;

    width: 53.125rem;
    color: #dfdad3;
    background-color: rgba(0,0,0,0.85);
    // background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/praise_top_bg.png');
    // background-size: 100% 100%;
    opacity: 0.85;
    padding: 1.875rem;
    border-radius: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.75rem 0;
    .content{
        width: 100%;
        font-size: 1.25rem;
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .school{
                font-size: 2.625rem;
            }
            .degree{
                margin-left: 2.8125rem;
                font-size: 1.5rem;
            }
            .locate{
                margin-left: auto;
                margin-right: 2.1875rem;
            }
        }
        .secondLine{
            margin-top: .375rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 1.5rem;
        }
        .detail{
            margin-top: .625rem;
            text-align: left;
        }

    }
}

.tagContainer{
    position: absolute;
    right: 4.375rem;
    top:30vh;
    transform: translateY(-20vh);
    overflow: hidden;
    height: 0rem;
    opacity: 0;
    // animation-duration: 3s;
    // animation-fill-mode: both;
    // animation-name: tagAnim;
    .showTag{
        // position: absolute;
        // width: 3.125rem;
        // height: 12.5rem;
        padding: 1.25rem .625rem;
        background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/bosstag.png');
        background-size: 100% 100%;
        .tagText{
            writing-mode: vertical-rl; /* 文字竖向排列 */
            text-orientation: upright; /* 使每个字保持直立 */
            text-align: center;
            color: white;
            margin: auto;
            font-size: 1.4375rem;
        }
    
    
        // mask-image: linear-gradient(to bottom, black 0%, black 100%); /* 设置遮罩 */
        // mask-size: 100% 0; /* 初始遮罩高度为0 */
    }
}

[data-aos="tag3"] {
    height: 0;
    opacity: 0;
}

:global([data-aos="tag3"].aos-animate) {
    height: 8.25rem ;
    opacity: 1;
    // transform: none;
}