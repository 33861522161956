.bgImg{
    width: 100%;
    height: 100vh;
    background: url("https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/huaguo2.jpg");
    background-size: 100% 100%;
    position: relative;
    font-family: STKaiti, Kaiti SC;
    display: flex;
    justify-content: center;
    align-items: center;
}

.galleryContainer{
    margin-left: 6.125rem;
    width: 53.125rem;
    color: #dfdad3;
    background-color: rgba(0,0,0,0.85);
    // background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/praise_top_bg.png');
    // background-size: 100% 100%;
    opacity: 0.85;
    padding: 1.875rem;
    border-radius: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .625rem .625rem;
    .web{
        font-size: 1.75rem;
        margin-bottom: .625rem;
    }
    .conn{
        margin-top: 2.5rem;
        font-size: 1.75rem;
        margin-bottom: .625rem;
    }

    .website{
        width: 100%;
        padding : 0 .625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 5.625rem;

        .card{
            cursor: pointer;
            .icon{
                width: 9.375rem;
                height: 9.375rem;
                border-radius: .3125rem;
            }
            .name{
                margin-top: .625rem;
                font-size: 1.25rem;
            }
        }

    }
    .connect{
        width: 80%;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        display: flex;
        .wechat{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .qrcode{
                width: 9.375rem;
                height: 9.375rem;
            }
        }
        .email{
            width: 9.375rem;
            height: 9.375rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.375rem;
        }

    }
    .iconList{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .connectIcon{
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

.tagContainer{
    position: absolute;
    right: 4.375rem;
    top:30vh;
    transform: translateY(-20vh);
    overflow: hidden;
    height: 0rem;
    opacity: 0;
    // animation-duration: 3s;
    // animation-fill-mode: both;
    // animation-name: tagAnim;
    .showTag{
        // position: absolute;
        // width: 3.125rem;
        // height: 12.5rem;
        padding: 1.25rem .625rem;
        background-image: url('https://myweb-1301075348.cos.ap-shanghai.myqcloud.com/assets/bosstag.png');
        background-size: 100% 100%;
        .tagText{
            writing-mode: vertical-rl; /* 文字竖向排列 */
            text-orientation: upright; /* 使每个字保持直立 */
            text-align: center;
            color: white;
            margin: auto;
            font-size: 1.4375rem;
        }
    
    
        // mask-image: linear-gradient(to bottom, black 0%, black 100%); /* 设置遮罩 */
        // mask-size: 100% 0; /* 初始遮罩高度为0 */
    }
}

[data-aos="tag5"] {
    height: 0;
    opacity: 0;
}

:global([data-aos="tag5"].aos-animate) {
    height: 9.5rem ;
    opacity: 1;
    // transform: none;
}